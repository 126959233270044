// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-featured-js": () => import("./../../../src/pages/featured.js" /* webpackChunkName: "component---src-pages-featured-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leaderboard-tsx": () => import("./../../../src/pages/leaderboard.tsx" /* webpackChunkName: "component---src-pages-leaderboard-tsx" */),
  "component---src-pages-message-board-js": () => import("./../../../src/pages/message_board.js" /* webpackChunkName: "component---src-pages-message-board-js" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-submit-tsx": () => import("./../../../src/pages/submit.tsx" /* webpackChunkName: "component---src-pages-submit-tsx" */)
}

